import React from "react";

import "./Main.css";

import Grid from "@mui/material/Grid";
// import Grid from "@mui/material/Unstable_Grid2";
import SmartToyOutlinedIcon from "@mui/icons-material/SmartToyOutlined";
import PrecisionManufacturingOutlinedIcon from "@mui/icons-material/PrecisionManufacturingOutlined";
import PsychologyOutlinedIcon from "@mui/icons-material/PsychologyOutlined";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import RocketIcon from '@mui/icons-material/Rocket';

import { pink, lime, blue } from "@mui/material/colors";

import Vision from "../components/Vision";
import FutureVideo from "../components/FutureVideo"
import AIVideo from "../components/AIVideo"
import PoCVideo from "../components/PoCVideo"
import Title from "./Title";
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

function Main() {
  // eslint-disable-next-line no-unused-vars
    const Item = styled(Paper)(({ theme }) => ({
      backgroundColor: theme.palette.mode === 'dark' ? 'rgba(0,0,0,0)' : '#fff',
      ...theme.typography.body2,
      padding: theme.spacing(7),
      margin: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    }));
  return (
    <div className="App">
      <div className="scroll">
        <Title />

<Box sx={{ flexGrow: 1, textAlign: 'center', px: 0 }}>
          <h1 data-scroll data-scroll-speed="17" data-scroll-position="top">
            <RocketIcon sx={{
              fontSize: '15rem', // Adjust the size as needed
              background: '-webkit-linear-gradient(#834d9b, #d04ed6)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              display: 'inline-block', // This makes the background clip work
            }} />
          </h1>
  {/* Add vertical space above the Vision component */}
  <Box sx={{ height: '50vh', width: '100%' }} />

          <Vision />

  {/* Centering the FutureVideo component */}
  <Box sx={{ my: 4, width: '100%', display: 'flex', justifyContent: 'center' }}>
    <FutureVideo />
  </Box>
          {/* Continue with similar simplifications for other sections */}


<Grid
  container
  spacing={3}
  direction="row"
  alignItems="center"
  justifyContent="center"
  className="pageContainer"
>
  <Grid item xs={12} sm={4} className="imageIcon rotateY">
    <SmartToyOutlinedIcon
      sx={{ fontSize: 500, color: pink[400] }}
      color="primary.main"
    />
  </Grid>
  <Grid item xs={12} sm={8} className="imageExplain">
    <h2>Robot and AI</h2><br/>
    <div>
      We can solve waste management problems with Robot and AI.
    </div>
  </Grid>
</Grid>


{/* section */}
<Grid
  container
  spacing={3}
  direction="row"
  alignItems="center"
  justifyContent="center"
  className="pageContainer"
>
  <Grid item xs={12} sm={4} className="imageIcon swing">
    <PrecisionManufacturingOutlinedIcon sx={{ fontSize: 500, color: lime[500] }} />
  </Grid>
  <Grid item xs={12} sm={8} className="imageExplain">
    <h2>
      To essencial workers
    </h2>
    <div>
      We use technology to reduce the risk and workload of essential work.
    </div>
  </Grid>
</Grid>

          {/* section */}

<Grid
  container
  spacing={3}
  direction="row"
  alignItems="center"
  justifyContent="center"
  className="pageContainer"
>
  {/* Remove the empty Grid items to prevent unnecessary space */}
  <Grid item xs={12} sm={4} className="imageIcon">
    <div className="gearOnTheHead">
      <PsychologyOutlinedIcon sx={{ fontSize: 500, color: blue[500] }} />
      <div className="gear rotate">
        <SettingsRoundedIcon
          sx={{ fontSize: 500, color: blue[400] }}
          style={{ fontSize: "2em" }}
        />
      </div>
    </div>
  </Grid>
  <Grid item xs={12} sm={8} className="imageExplain">
    <h2>AI to sort trash</h2>
    <div>
      Our AI is 98% accurate in garbage sorting.
    </div>
  </Grid>
</Grid>

  <Box sx={{ my: 4, width: '100%', display: 'flex', justifyContent: 'center' }}>
                      <AIVideo />
          </Box>
    <Box sx={{ my: 4, width: '100%', display: 'flex', justifyContent: 'center' }}>
                      <PoCVideo />
  </Box>
</Box>
      </div>
    </div>
  );
}

export default Main;
