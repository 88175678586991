import React from "react";

import "./Belts.css";

function Belts() {
  return (
    <div>
      <div className="c-direction-block" id="direction">
        <div className="c-direction-block_item -one">
          <span
            className="c-direction-block_item_inner"
            data-scroll
            data-scroll-speed="10"
            data-scroll-position="top"
            data-scroll-direction="horizontal"
            data-scroll-target="#direction"
          >
            We're moving into the future
          </span>
        </div>
        <div className="c-direction-block_item -two">
          <span
            className="c-direction-block_item_inner"
            data-scroll
            data-scroll-direction="horizontal"
            data-scroll-speed="-23"
            data-scroll-position="top"
            data-scroll-target="#direction"
          >
            Hello new world!!!
          </span>
        </div>
        <div className="c-direction-block_item -three">
          <span
            className="c-direction-block_item_inner"
            data-scroll
            data-scroll-direction="horizontal"
            data-scroll-speed="35"
            data-scroll-target="#direction"
          >
            To sooo cool future{"==>"}
          </span>
        </div>
        <div className="c-direction-block_item -four">
          <span
            className="c-direction-block_item_inner"
            data-scroll
            data-scroll-direction="horizontal"
            data-scroll-speed="21"
            data-scroll-target="#direction"
          >
            Let's have fun together!
          </span>
        </div>
        <div className="c-direction-block_item -five">
          <span
            className="c-direction-block_item_inner"
            data-scroll
            data-scroll-direction="horizontal"
            data-scroll-speed="-35"
            data-scroll-target="#direction"
            // data-scroll-delay="0.05"
          >
            Hey, join us!!!!
          </span>
        </div>
      </div>
    </div>
  );
}

export default Belts;
