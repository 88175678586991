import React from 'react'
import Youtube from 'react-youtube';
import './videos.css'
import useWindowSize from '../hooks/useWindowSize'


function AIVideo() {
    const [width, height ] = useWindowSize();

    const opts = {
      height: height,
      width: width * 0.4,
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        playsinline: 1,
        autoplay: 1,
        mute: 1,
        loop: 1,
      },
    };
    return (
      <Youtube
            videoId="3mS9vWtUBqE" // you can insert end of the letter of youtube url
            opts={opts}
            className="iframe"
            containerClassName="youtube"
        />
    );
  }
export default AIVideo;

