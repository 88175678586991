import React from "react";

const style = {
  fontSize: "2.2rem",
};

const Vision = () => {
  return (
    <>
      <h2 {...{ style }}> From Trash To Space🚀 </h2>
      <br />
    </>
  );
};

export default Vision;
