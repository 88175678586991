import React from 'react';
import Grid from "@mui/material/Unstable_Grid2";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import ItoenLogo from "../assets/Itoen-transparent-ito-en-hd-logo.png"
import NumataLogo from "../assets/numata-logo-transparent.png"
import MuscleLogo from "../assets/muscle-logo-transparent-png.png"
import SustainaSeedLogo from "../assets/sustainaSeed-cropped-logo_main_014.png"

const Supporter = () => {
    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(7),
        margin: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
      }));

    return (
        <>
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={3}>
                <Grid xs={12} md={6}>
                    <Item><img src={ItoenLogo} alt="ItoenLogo"/></Item>
                    </Grid>
                    <Grid xs={12} md={6}>
                    <Item><img src={MuscleLogo} alt="MuscleLogo"/></Item>
                    </Grid>
                    <Grid xs={12} md={6}>
                    <Item><img src={SustainaSeedLogo} alt="SustainaSeedLogo"/></Item>
                    </Grid>
                    <Grid xs={12} md={6}>
                    <Item><img src={NumataLogo} alt="NumataLogo"/></Item>
                    </Grid>
            </Grid>
        </Box>
        </>
    );
};

export default Supporter;
