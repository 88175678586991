import React from "react";

import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

import kanaiimage from "../assets/aladdinlogo.png";

import "./Cards.css";

import { deepOrange } from "@mui/material/colors";


const Cards = ({ post, name, career, sns }) => {
  return (
    <>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {post}
        </Typography>
        <Typography variant="h5" component="div">
          {name}
        </Typography>
        <Box style={{ padding: "2vh" }}>
          <Avatar
            alt="Yuki Kanai"
            src={kanaiimage}
            sx={{ width: 56, height: 56, bgcolor: deepOrange[500] }}
          />
        </Box>
        <Typography variant="body2">
          <h4>Career</h4>
          <br />
          {career}
        </Typography>

      </CardContent>

      <CardActions disableSpacing style={{ paddingLeft: "6vh" }}>
        <IconButton aria-label="linkedIn" size="inherit" href={sns} target="_blank">
          <LinkedInIcon fontSize="inherit"/>
        </IconButton>
      </CardActions>

    </>
  );
};

export default Cards;
