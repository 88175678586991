import React, { useRef, useState, useEffect } from "react";

import Main from "./components/Main";

import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import { LocomotiveScrollProvider } from "react-locomotive-scroll";

import Grid from "@mui/material/Unstable_Grid2"

import Profile from "./components/Profile";
import Contact from "./Contact";
import Belts from "./components//Belts";
import Supporter from "./components/Supporter"

// import greenlogo from "./assets/greenlogo.png"

function App() {
  const theme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  const containerRef = useRef(null);

  const [isPC, setIsPC] = useState(true);

  useEffect(() => {
    if (window.matchMedia && window.matchMedia("(max-device-width: 640px)").matches) {
      return setIsPC(false);
    } else {
      return setIsPC(true);
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <LocomotiveScrollProvider
        options={{
          smooth: true,
          // ... all available Locomotive Scroll instance options
        }}
        watch={
          [
            //..all the dependencies you want to watch to update the scroll.
            //  Basicaly, you would want to watch page/location changes
            //  For exemple, on Next.js you would want to watch properties like `router.asPath` (you may want to add more criterias if the instance should be update on locations with query parameters)
          ]
        }
        containerRef={containerRef}
      >
        <main data-scroll-container ref={containerRef}>
          <div style={{ position: "absolute", marginTop: "110vh" }}>{isPC && <Belts />}</div>

          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="space-around"
          >
            <Grid item xs={10} sm={8} md={8} lg={8} xl={8}>
              <Main />
            </Grid>

            <Grid item xs={12} sm={8} md={6} lg={5} xl={8} style={{ paddingBottom: "35vh" }}>
              <Profile />
            </Grid>

            <div style={{ fontSize: "6vh" }}>Supporter</div>
            <Grid item xs={12} sm={8} md={6} lg={5} xl={4} style={{ padding: "2vh 3vh 35vh 3vh" }}>
              {/* <img src={greenlogo} alt="gleenlogo"/> */}
              <Supporter />
            </Grid>


            <div style={{ fontSize: "6vh" }}>Reviews</div>
            {/* <Grid item xs={12} sm={8} md={6} lg={5} xl={8} style={{ paddingBottom: "35vh" }}> */}
            <div style={{ fontSize: "3vh", paddingBottom: "6vh" }}>Coming soon...</div>
            {/* </Grid> */}


            <div style={{ fontSize: "6vh" }}>Contact</div>
            <Grid item xs={12} sm={8} md={6} lg={5} xl={4}
              style={{
flexGrow: 1, textAlign: 'center', px: 0
              }}
            >
              <Contact />


              <br/>
              <br/>
              <br/>
            </Grid>

          </Grid>
        </main>
      </LocomotiveScrollProvider>
    </ThemeProvider>
  );
}

export default App;
