// import React, { useRef, useEffect } from 'react'
// import video_mp4_PoC from "../assets/poc-sorting-at-itoen.mp4"
// import Button from '@mui/material/Button';


import React from 'react'
import Youtube from 'react-youtube';
import './videos.css'
import useWindowSize from '../hooks/useWindowSize'

// function PoCVideo() {
//     const videoRef = useRef(null);
//     useEffect(() => {
//         videoRef.current?.play();
//     }, []);
//     const StartReplay = () => {
//        if (videoRef.current?.currentTime) {
//         videoRef.current.currentTime = 0;
//         }
//         videoRef.current?.play();
//     }
//     const [width ] = useWindowSize();

//     return (
//         <>
//             <video controls muted ref={videoRef} width={width * 0.4} >
//                 <source src={video_mp4_PoC} type="video/mp4" />
//                 <p>Your browser doesn't support HTML5 video.</p>
//             </video>
//             <br />
//             <br />
//             <Button onClick={StartReplay} children="replay" variant="contained" color="primary" />
//         </>
//     );
    // }


function PoCVideo() {
    const [width, height ] = useWindowSize();

    const opts = {
      height: height,
      width: width * 0.4,
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        playsinline: 1,
        autoplay: 1,
        mute: 1,
        loop: 1,
      },
    };

    return (
      <Youtube
            videoId="GZ6ATK-RwU0" // you can insert end of the letter of youtube url
            opts={opts}
            className="iframe"
            containerClassName="youtube"
        />
    );
}

export default PoCVideo;

