import React from "react";

import Grid from "@mui/material/Grid";
import Typical from "react-typical";

import "./Title.css";

const Title = () => {
  return (
    <>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "100vh" }}
      >
        <Grid item xs={4}>
          <h1 data-scroll data-scroll-speed="17" data-scroll-position="top">
            Aladdin inc.
          </h1>
        </Grid>

        <Grid item xs={4} className="subtitle">
          {/* <h2
                data-scroll
                data-scroll-speed="-9"
                data-scroll-position="top"
                data-scroll-direction="horizontal"
              >
                To go <span>Sci-Fi</span> world.
              </h2> */}
          <h2
            data-scroll
            data-scroll-speed="-9"
            data-scroll-position="top"
            data-scroll-direction="horizontal"
          >
            To{" "}
            <Typical
              steps={["Sci-Fi🤖", 2000, "convenient", 2000, "a happy", 2000]}
              loop={Infinity}
              wrapper="span"
            />
            world!
          </h2>
        </Grid>
      </Grid>
    </>
  );
};

export default Title;
